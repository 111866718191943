<template>
  <div id="pet-register">
    <pet-register-layout></pet-register-layout>
  </div>
</template>

<script>
import PetRegisterLayout from '../layout/PetRegisterLayout.vue'

export default {
  name: 'PetRegister',
  components: {
    PetRegisterLayout
  }
}
</script>
